import React, { useEffect,useState,useRef, useCallback } from 'react';
import './Sidebar.css';
// import provinceData from '../data/provinceData';


const mainRestId = 142;
const fetchUrl="https://api.iorders.online/customer/retrieve_restaurants";
const orderOnlineLink="https://orders.iorders.online/";
const orderDeliveryLink = (storeTitle) => {
  if (storeTitle === "p-lovepizza-leduc") {
    return "https://www.order.store/ca/store/lovepizza-leduc/1HeZhJyCVP-3e1XX5eq3fw";
  }
  if (storeTitle === "p-lovepizza-spruce-grove") {
    return "https://www.skipthedishes.com/love-pizza-spruce-grove";
  }
  if (storeTitle === "p-lovepizza-heritage-valley") {
    return "https://www.ubereats.com/ca/store/lovepizza-heritage-valley/CQSxii7OXQS5vzEYzF3oew?diningMode=PICKUP&pl=JTdCJTIyYWRkcmVzcyUyMiUzQSUyMjI1MTglMjBFJTIwUXVhbmNlJTIwU3QlMjIlMkMlMjJyZWZlcmVuY2UlMjIlM0ElMjIyZDQzOGJhNC02NDc0LTkyMTQtZTNmOS0zYWM2NWQ5ZWMzYWIlMjIlMkMlMjJyZWZlcmVuY2VUeXBlJTIyJTNBJTIydWJlcl9wbGFjZXMlMjIlMkMlMjJsYXRpdHVkZSUyMiUzQTUwLjQ0MzY1MzMlMkMlMjJsb25naXR1ZGUlMjIlM0EtMTA0LjU0ODA2MDIlN0Q%3D&ps=1";
  }
  if (storeTitle === "p-lovepizza-canterra") {
    return "https://www.order.store/ca/store/lovepizza-downtown/-txHYqc6UoaBGDQv7zq8Bg?ps=1";
  }
  if (storeTitle === "p-lovepizza-rabbit-hill") {
    return "https://www.order.store/ca/store/lovepizza-rabbit-hill/yO3z-BEsURGwqsEqc7JESA";
  }
  return "https://orders.iorders.online/";
};

// const mainRestId = 7;
// const fetchUrl="https://devapi.iorders.online/customer/retrieve_restaurants";
// const orderOnlineLink="https://demo.iorders.online/";

function Sidebar({ onProvinceChange, onCoordinatesChange, onStoresChange }) {

  // const handleProvinceSelect = (event) => {
  //   onProvinceChange(event.target.value);
  // };

  const [stores, setStores] = useState([]);
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [cities, setCities] = useState([]);
  // const [province, setProvince] = useState("");
  const autocomplete = useRef(null);
  const [filterActive, setFilterActive] = useState(false);
  const [userLocation, setUserLocation] = useState({ lat: 0, lng: 0 });

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setFilterActive(true);
  };

  const handlePlaceSelect = useCallback(() => {
    if (!autocomplete.current) {
      console.error("Autocomplete is not initialized yet.");
      return;
    }
    let addressObject = autocomplete.current.getPlace();
    if (addressObject.geometry) {
      const lat = addressObject.geometry.location.lat();
      const lng = addressObject.geometry.location.lng();
      onCoordinatesChange({ lat, lng }); // Pass the coordinates up to the parent component
      setFilterActive(true);
      setUserLocation({ lat, lng });
    }
    if (addressObject && addressObject.address_components) {
      setAddress(addressObject.formatted_address);
      const addressComponents = addressObject.address_components;
      const locality = addressComponents.find(comp => comp.types.includes("locality"));
      // const administrativeArea = addressComponents.find(comp => comp.types.includes("administrative_area_level_1"));
      setCity(locality ? locality.long_name : "");
  
      // Update selectedCity state to reflect the new city from autocomplete
      setSelectedCity(locality ? locality.long_name : "");
  
      // const provinceCode = administrativeArea ? administrativeArea.short_name : "";
      // if (provinceData[provinceCode]) {
      //   setProvince(provinceCode);
      //   onProvinceChange(provinceCode); // Directly call the onProvinceChange function
      // } else {
      //   console.error("Extracted province code does not match any known provinces:", provinceCode);
      // }
    }
  }, [setAddress, setCity, setSelectedCity, onCoordinatesChange, setFilterActive, setUserLocation]); // Include setSelectedCity in the dependency array // Include all used state setters and callbacks here
  
  useEffect(() => {
    const google = window.google;
    if (!google) {
      console.error("Google Maps JavaScript API not loaded");
      return;
    }
  
    autocomplete.current = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      { types: ['geocode'],
      componentRestrictions: { country: 'CA' } }
    );
    autocomplete.current.setFields(['address_components', 'formatted_address', 'geometry']);
    autocomplete.current.addListener('place_changed', handlePlaceSelect);
  
    return () => {
      google.maps.event.clearInstanceListeners(autocomplete.current);
    };
  }, [handlePlaceSelect]);
  
  function calculateDistance(lat1, lon1, lat2, lon2) {
    const google = window.google;
    if (!google) {
      console.error("Google Maps JavaScript API not loaded");
      return;
    }
    if (lat1 === 0 && lon1 === 0) {
      return Promise.resolve("Location not set");
    }
  
    const origin = new google.maps.LatLng(lat1, lon1);
    const destination = new google.maps.LatLng(lat2, lon2);
  
    return new Promise((resolve, reject) => {
      const service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix({
        origins: [origin],
        destinations: [destination],
        travelMode: 'DRIVING',
      }, (response, status) => {
        if (status === 'OK') {
          const result = response.rows[0].elements[0];
          if (result.status === 'OK') {
            resolve(result.distance.text); // This gives you the distance in km or meters
          } else {
            reject('Distance calculation failed: ' + result.status);
          }
        } else {
          reject('Distance Matrix request failed: ' + status);
        }
      });
    });
  }

  useEffect(() => {
    const fetchData = async () => {
      const url = fetchUrl;
      const bodyData = {
          main_restaurant_id: mainRestId,
          method: "get_all_restaurants_for_customer"
      };
    
      try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyData)
        });
        const data = await response.json();
        let citySet = new Set();
        const currentDay = new Date().toLocaleString("en-CA", { weekday: 'long' }).toUpperCase();
        const currentTime = new Date().toTimeString().split(' ')[0];
        // const currentTime='19:54:22'
        // console.log(currentDay, currentTime);
        let extractedData = data.data.restaurants
        .filter(restaurant => restaurant.profile.id !== mainRestId)
        .map(restaurant => {
          const city = restaurant.profile.address.split(",")[1].trim(); // Assuming city is the second element after splitting by comma
          citySet.add(city);
          const todaySchedules = restaurant.profile.available_days.filter(day => day.day === currentDay);
          let todayTakeoutAvailable = false;
          let todayDeliveryAvailable = false;
          // Determine if there is a split in order modes across all days
          let takeoutDeliverySplit = restaurant.profile.available_days.some(schedule => schedule.order_mode === 0);
          todaySchedules.forEach(schedule => {
            const isOpenNow = currentTime >= schedule.opening_time && currentTime <= schedule.closing_time;
            if (isOpenNow) {
              // Calculate time until closing
              const closingTime = new Date();
              const [closingHours, closingMinutes] = schedule.closing_time.split(':');
              closingTime.setHours(closingHours, closingMinutes, 0);
              const timeDiff = (closingTime - new Date()) / 60000; // Difference in minutes

              // Check if the store reopens within a minute after closing
              const reopeningTime = new Date(closingTime);
              reopeningTime.setMinutes(reopeningTime.getMinutes() + 1);
              const reopensSoon = restaurant.profile.available_days.some(day => {
                const [openingHours, openingMinutes] = day.opening_time.split(':');
                const openingTime = new Date();
                openingTime.setHours(openingHours, openingMinutes, 0);
                return openingTime.getTime() === reopeningTime.getTime();
              });

              if (timeDiff < 30 && !reopensSoon) {
                restaurant.closingSoon = `Closing in ${Math.ceil(timeDiff)} minutes`;
              }
            }
            if (schedule.order_mode === 1 && isOpenNow) {
              todayTakeoutAvailable = true;
            }
            if (schedule.order_mode === 0 && isOpenNow) {
              todayDeliveryAvailable = true;
            }
          });
  
          // If there is no split, set both takeout and delivery to the same availability
          if (!takeoutDeliverySplit) {
            todayTakeoutAvailable = todayDeliveryAvailable = todayTakeoutAvailable || todayDeliveryAvailable;
          }
  
          const isClosed = restaurant.profile.close_restaurent || (!todayTakeoutAvailable && !todayDeliveryAvailable);
          let nextOpeningTime = null;
    
          if (isClosed && !restaurant.profile.close_restaurent) {
            // Find the next day the restaurant is open
            const daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
            const currentDayIndex = daysOfWeek.indexOf(currentDay);
            const sortedDays = restaurant.profile.available_days
              .map(day => ({
                ...day,
                dayIndex: daysOfWeek.indexOf(day.day)
              }))
              .sort((a, b) => a.dayIndex - b.dayIndex);
            // Check if there's a later opening time today
            const laterToday = sortedDays
            .filter(day => day.dayIndex === currentDayIndex && currentTime < day.opening_time)
            .sort((a, b) => a.opening_time.localeCompare(b.opening_time))[0];

            if (laterToday) {
              nextOpeningTime = `Opening Today at ${laterToday.opening_time}`;
            } else {
            // Find the next opening day
            const nextDay = sortedDays.find(day => day.dayIndex > currentDayIndex) || sortedDays[0];
            nextOpeningTime = nextDay ? `Reopens On ${daysOfWeek[nextDay.dayIndex]} at ${nextDay.opening_time}` : "Not available";
            }
          }
          return {
            name: restaurant.profile.restaurant_name,
            address: restaurant.profile.address,
            status: isClosed ? "Closed" : "Open",
            reopen: nextOpeningTime,
            phone: restaurant.profile.phone,
            lat: restaurant.profile.lat,
            lng: restaurant.profile.lng,
            title: restaurant.profile.title,
            takeout: todayTakeoutAvailable ? "Takeout Available" : "No Takeout",
            delivery: todayDeliveryAvailable ? "Delivery Available" : "No Delivery",
            distance: null,
            closingSoon: restaurant.closingSoon || null,
            city: city
          };
        });
        if (filterActive) {
          let filtered_stores = extractedData.filter(restaurant => restaurant.city === selectedCity);
          if (filtered_stores.length === 0) {
            for (let store of extractedData) {
              let distance = await calculateDistance(userLocation.lat, userLocation.lng, store.lat, store.lng);
              if (parseFloat(distance) <= 15) {
                filtered_stores.push(store);
              }
            }
          }
            extractedData = filtered_stores;
        }
        
        if (userLocation.lat !== 0 || userLocation.lng !== 0) {
          for (let store of extractedData) {
            store.distance = await calculateDistance(userLocation.lat, userLocation.lng, store.lat, store.lng);
          }
          // Sort by distance if distance is calculated
          extractedData.sort((a, b) => {
            return a.distance && b.distance ? parseFloat(a.distance) - parseFloat(b.distance) : 0;
          });
        }
        setCities([...citySet]);
        setStores(extractedData);
        onStoresChange(extractedData);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };
  
  fetchData(); 
  },[city, filterActive, userLocation, selectedCity]);

 
  return (
    <div className="sidebar">
      <h3>Enter your address to find the store closest to you, or select a city to find stores.</h3>
      <input
        id="autocomplete"
        type="text"
        placeholder="Address"
        className="input-field"
        value={address}
        onChange={e => setAddress(e.target.value)}
        autoComplete="new-password"
      />
      <select className="select-field" value={selectedCity} onChange={handleCityChange} autoComplete="off">
        <option value="" disabled>Select a City</option>
        {cities.map((city, index) => (
          <option key={index} value={city}>{city}</option>
        ))}
      </select>
      {/* <select className="select-field" value={province} onChange={handleProvinceSelect} autoComplete="off">
        <option>Select a Province</option>
        {Object.keys(provinceData).map((code) => (
          <option key={code} value={code}>{provinceData[code].name}</option>
        ))}
      </select> */}
      
      <div className="store-list">
        <h2>Nearby Stores</h2>
        {stores.length > 0 ? (
          stores.map((store, index) => (
            <div key={index} className="store-item">
              <h3>{store.name}<br/><span style={{ fontSize:'13px',color:'#666666',marginTop:'3px',marginBottom:'0px' }}>{store.address}</span></h3>
              <div>
                
                <div className="store-status" style={{ color: store.status === "Open" ? 'green' : 'red',fontSize:'16px'}}>
                  <span style={{ fontWeight: 'bold',fontSize:'20px'}}>{store.status}</span>
                  
                  {store.status === "Open" && (
                    <div style={{ paddingBottom: '10px' }}>
                      <div style={{ color: 'black',margin:'0px'}}>  Takeout:
                      {store.takeout === "Takeout Available" ? 
                        <span style={{ color: 'green' , marginLeft: '10px',marginRight: '10px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 15" width="30" height="30" fill="currentColor"><path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path></svg>
                          </span> : 
                        <span style={{ color: 'red' , marginLeft: '10px',marginRight: '10px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 14" width="30" height="30" fill="currentColor"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
                        </span>
                      } 
                       Delivery:
                      {store.delivery === "Delivery Available" ? 
                        <span style={{ color: 'green' , marginLeft: '10px',marginRight: '10px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 15" width="30" height="30" fill="currentColor"><path d="M11.602 13.7599L13.014 15.1719L21.4795 6.7063L22.8938 8.12051L13.014 18.0003L6.65 11.6363L8.06421 10.2221L10.189 12.3469L11.6025 13.7594L11.602 13.7599ZM11.6037 10.9322L16.5563 5.97949L17.9666 7.38977L13.014 12.3424L11.6037 10.9322ZM8.77698 16.5873L7.36396 18.0003L1 11.6363L2.41421 10.2221L3.82723 11.6352L3.82604 11.6363L8.77698 16.5873Z"></path></svg>
                        </span> : 
                        <span style={{ color: 'red', marginLeft: '10px',marginRight: '10px'  }}>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 13" width="30" height="30" fill="currentColor"><path d="M11.9997 10.5865L16.9495 5.63672L18.3637 7.05093L13.4139 12.0007L18.3637 16.9504L16.9495 18.3646L11.9997 13.4149L7.04996 18.3646L5.63574 16.9504L10.5855 12.0007L5.63574 7.05093L7.04996 5.63672L11.9997 10.5865Z"></path></svg>
                        </span>
                      }
                      </div>
                    </div>
                  )}
                </div>
                {store.status === "Closed" && store.reopen && (
                  <p className="store-status" style={{ color: 'red' }}>
                    {store.reopen}
                  </p>
                )}
                {store.distance && (
                <p style={{ color: 'green', marginTop: '10px' }}>{store.distance}s Away</p>
              )}
              {store.closingSoon && store.status === "Open" &&(
                <p className="store-status" style={{ color: 'orange' }}>
                  {store.closingSoon}
                </p>
              )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', width: '100%' }}>
                <button className="preorder-button" style={{ flex: 1 }}
                        onClick={() => window.open(orderOnlineLink+store.title, '_blank')}>
                    {store.status === "Open" ? "Order Online" : "Pre Order Online"}
                </button>
                {/* <button className="preorder-button" style={{ flex: 1 }}
                        onClick={() => window.open(orderDeliveryLink(store.title), '_blank')}>
                    {store.status === "Open" ? "Order Delivery" : "Pre Order Delivery"}
                </button> */}
              </div>
              
            </div>
          ))
        ) : (
          <p className='no-store'>We are currently not available in this location.</p>
        )}
      </div>
    </div>
  );
}

export default Sidebar;

